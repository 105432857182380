"use client";
import Button from "@/components/common/button";
import Image from "next/image";
import Link from "next/link";
import { FC, useContext, useEffect, useState } from "react";
import gbciLogo from "../../../public/gbci-logo.png";
import { AuthContext } from "@/context/AuthContext";
import { HamburgerMenu } from "./svgs/hamburguer-menu";
import useBreakpoint from "@/hooks/useBreakpoint";
import SignInModal from "@/components/sign-in-modal";
// import { PROFILE_IMAGES_URL, SERVER_API_URL } from "@/constants/server";
import random from "../../../public/images/figures/testimonals/default.jpg";
import DropdownButton from "@/components/common/lang";

import { headerBarEN } from "@/assets/dictionaries/en/header-content";
import { headerBarES } from "@/assets/dictionaries/es/header-content";

const PROFILE_IMAGES_URL = process.env.NEXT_PUBLIC_PROFILE_IMAGES_URL;
const Header: FC = () => {
  const { auth, logOut } = useContext(AuthContext);

  const [showToolSubMenu, setShowToolSubMenu] = useState(false);
  const [showServicesSubMenu, setShowServicesSubMenu] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [displaySignInModal, setDisplaySignInModal] = useState(false);

  const breakpoint = useBreakpoint();
  const isMobileViewport = breakpoint.sm || breakpoint.md || breakpoint.lg;

  function HeaderReset() {
    setShowToolSubMenu(false);
    setShowServicesSubMenu(false);
    setShowHamburgerMenu(false);
  }

  function ToolChange() {
    setShowToolSubMenu((prev) => !prev);
    setShowServicesSubMenu(false);
  }

  function ServiceChange() {
    setShowServicesSubMenu((prev) => !prev);
    setShowToolSubMenu(false);
  }

  const _safeReadFromSessionStorage = (key: string) => {
    try {
      const stored = sessionStorage.getItem(key);
      console.log(stored);
      return stored || null; // Always return null if not found
    } catch (e) {
      return null;
    }
  };

  const [headerContent, setHeaderContent] = useState(headerBarEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setHeaderContent(headerBarES);
        break;
      case "EN":
      default:
        setHeaderContent(headerBarEN);
        break;
    }
  }, []);

  const NavBar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

    useEffect(() => {
      const loggedInStatus = auth.isLoggedIn;
      setIsLoggedIn(loggedInStatus);
    }, []);

    if (isLoggedIn === null)
      return (
        <nav>
          <ul
            className={
              isMobileViewport
                ? "absolute px-page-xl bg-gradient-to-br from-slate-900 to-slate-700 left-0 right-0 flex flex-col p-8 gap-8 items-center text-xl"
                : "flex gap-4 items-center text-base h-[60px]"
            }
          ></ul>
        </nav>
      );

    return (
      <nav>
        {isLoggedIn ? (
          <ul
            className={
              isMobileViewport
                ? "absolute px-page-xl bg-gradient-to-br from-slate-900 to-slate-700 left-0 right-0 flex flex-col p-8 gap-8 items-center text-xl"
                : "flex gap-4 items-center text-base h-[60px]"
            }
          >
            <li>
              <Link href="/">
                <button onClick={HeaderReset}>
                  {headerContent.header_home}
                </button>
              </Link>
            </li>
            <li className="cursor-pointer">
              <button onClick={ToolChange}>
                {headerContent.header_tools}{" "}
                {showToolSubMenu ? <>&#9650;</> : <>&#9660;</>}
              </button>
            </li>
            <li className="cursor-pointer">
              <button onClick={ServiceChange}>
                {headerContent.header_service}{" "}
                {showServicesSubMenu ? <>&#9650;</> : <>&#9660;</>}
              </button>
            </li>
            {/* <li>
              <Link href="/service">
                <button onClick={HeaderReset}>
                  {headerContent.header_service}
                </button>
              </Link>
            </li> */}
            {showToolSubMenu && isMobileViewport && (
              <li>
                <MenuItemResponsive
                  route={headerContent.tools_available}
                ></MenuItemResponsive>
              </li>
            )}
            {showServicesSubMenu && isMobileViewport && (
              <li>
                <MenuItemResponsive
                  route={headerContent.services_available}
                ></MenuItemResponsive>
              </li>
            )}
            {/* <li>
                <Link href="/academy">
                  <button onClick={HeaderReset}>Academy</button>
                </Link>
              </li> */}
            <li>
              <Link href="/games">
                <button onClick={HeaderReset}>
                  {headerContent.header_games}
                </button>
              </Link>
            </li>
            {/* <li>
                <Link href="/insights">
                  <button onClick={HeaderReset}>Insights</button>
                </Link>
              </li> */}

            <li>
              <Link href="/about">
                <button onClick={HeaderReset}>
                  {headerContent.header_about_us}
                </button>
              </Link>
            </li>
            <li>
              <Link href="/#contact">
                <button onClick={HeaderReset}>
                  {headerContent.header_contact}
                </button>
              </Link>
            </li>
            <li>
              <Link href="/profile">
                <button
                  className="h-[60px] w-[91px] pl-2 flex gap-2 items-center"
                  onClick={HeaderReset}
                >
                  {auth.currentUser && auth.currentUser.photo_url ? (
                    <img
                      className="w-10 h-10 rounded-full"
                      width={40}
                      height={40}
                      src={`${PROFILE_IMAGES_URL}${auth.currentUser.photo_url}`}
                      alt=""
                    />
                  ) : (
                    <Image
                      className="w-10 h-10 rounded-full"
                      width={40}
                      height={40}
                      src={random}
                      alt=""
                    />
                  )}

                  <p>{headerContent.header_profile}</p>
                </button>
              </Link>
            </li>

            <li className="px-8 xl:px-0 xl:absolute xl:right-4">
              <button onClick={() => logOut()}>
                {headerContent.header_sign_out}
              </button>
            </li>
          </ul>
        ) : (
          <ul
            className={
              isMobileViewport
                ? "absolute px-page-xl bg-gradient-to-br from-slate-900 to-slate-700 left-0 right-0 flex flex-col p-8 gap-8 items-center text-xl"
                : "flex gap-4 items-center text-base h-[60px]"
            }
          >
            <li>
              <Link href="/">
                <button onClick={HeaderReset}>
                  {headerContent.header_home}
                </button>
              </Link>
            </li>
            <li className="cursor-pointer">
              <button onClick={ToolChange}>
                {headerContent.header_tools}{" "}
                {showToolSubMenu ? <>&#9650;</> : <>&#9660;</>}
              </button>
            </li>
            {showToolSubMenu && isMobileViewport && (
              <li>
                <MenuItemResponsive
                  route={headerContent.tools_available}
                ></MenuItemResponsive>
              </li>
            )}
            <li className="cursor-pointer">
              <button onClick={ServiceChange}>
                {headerContent.header_service}{" "}
                {showServicesSubMenu ? <>&#9650;</> : <>&#9660;</>}
              </button>
            </li>
            {/* <li>
              <Link href="/service">
                <button onClick={HeaderReset}>
                  {headerContent.header_service}
                </button>
              </Link>
            </li> */}

            {showServicesSubMenu && isMobileViewport && (
              <li>
                <MenuItemResponsive
                  route={headerContent.services_available}
                ></MenuItemResponsive>
              </li>
            )}
            {/* <li>
                  <Link href="/academy">
                    <button onClick={HeaderReset}>Academy</button>
                  </Link>
                </li> */}
            <li>
              <Link href="/games">
                <button onClick={HeaderReset}>
                  {headerContent.header_games}
                </button>
              </Link>
            </li>
            {/* <li>
                  <Link href="/insights">
                    <button onClick={HeaderReset}>Insights</button>
                  </Link>
                </li> */}

            <li>
              <Link href="/about">
                <button onClick={HeaderReset}>
                  {headerContent.header_about_us}
                </button>
              </Link>
            </li>
            <li>
              <Link href="/#contact">
                <button onClick={HeaderReset}>
                  {headerContent.header_contact}
                </button>
              </Link>
            </li>
            <li>
              <Button
                size="large"
                onClick={() => {
                  setDisplaySignInModal(true);
                }}
              >
                {headerContent.header_sign_in}
              </Button>
            </li>
          </ul>
        )}
      </nav>
    );
  };

  const MenuItem: FC<{ header: string; nav: { [x: string]: string }[] }> = ({
    header,
    nav,
  }) => {
    return (
      <div className="">
        <h2 className="text-2xl font-bold">{header}</h2>
        <nav className="text-xl">
          <ul className="flex flex-col">
            {nav.map((item) => {
              const [navItem, navLink] = Object.entries(item)[0];

              return (
                <li key={navLink}>
                  <Link className="hover:underline" href={navLink}>
                    <button onClick={HeaderReset}>{navItem}</button>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  };

  const MenuItemResponsive: FC<{ route: { [x: string]: string }[] }> = ({
    route,
  }) => {
    return (
      <ul>
        {route.map((item) => {
          const [routeItem, routeLink] = Object.entries(item)[0];

          return (
            <li key={routeLink}>
              <Link href={routeLink}>
                <button onClick={HeaderReset}>{routeItem}</button>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const MenuTools: FC = () => {
    return (
      <div className="absolute px-page-xl py-8 flex gap-32 bg-gradient-to-br from-slate-700 to-gbci-primary left-0 right-0">
        <MenuItem
          header={headerContent.header_tools}
          nav={headerContent.tools_available}
        />
      </div>
    );
  };

  const MenuServices: FC = () => {
    return (
      <div className="absolute px-page-xl py-8 flex gap-32 bg-gradient-to-br from-slate-700 to-gbci-primary left-0 right-0">
        <MenuItem
          header={headerContent.header_service}
          nav={headerContent.services_available}
        />
      </div>
    );
  };

  return (
    <header className="relative bg-gradient-to-br from-slate-900 to-slate-700 text-white shadow-md z-50 border-t-8 border-gbci-accent">
      <div className="flex justify-between items-center px-page-xl py-4">
        <Image
          className="w-[150px] h-[34px] translate-y-1"
          src={gbciLogo}
          alt="GBCI Group"
        />

        {isMobileViewport ? (
          <div className="">
            <div
              className="p-2 rounded-md "
              onClick={() => setShowHamburgerMenu((prev) => !prev)}
            >
              <HamburgerMenu className="fill-white w-10 h-10" />
            </div>
          </div>
        ) : (
          <NavBar />
        )}
      </div>

      {showToolSubMenu && !isMobileViewport && <MenuTools />}

      {showServicesSubMenu && !isMobileViewport && <MenuServices />}

      {showHamburgerMenu && isMobileViewport && <NavBar />}

      {displaySignInModal && (
        <SignInModal handleClose={() => setDisplaySignInModal(false)} />
      )}
      <DropdownButton></DropdownButton>
    </header>
  );
};

export default Header;
