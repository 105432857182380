interface FooterBarEN {
  footer_contact: string;
  footer_follow_us: string;
  footer_site: string;
  footer_privacy: string;
  footer_cookies: string;
  footer_faq: string;
}

export const footerBarEN: FooterBarEN = {
  footer_contact: "Contact",
  footer_follow_us: "Follow Us",
  footer_site: "Site Map",
  footer_privacy: "Privacy Policy",
  footer_cookies: "Cookies",
  footer_faq: "FAQ",
};
