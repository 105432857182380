interface FooterBarES {
  footer_contact: string;
  footer_follow_us: string;
  footer_site: string;
  footer_privacy: string;
  footer_cookies: string;
  footer_faq: string;
}

export const footerBarES: FooterBarES = {
  footer_contact: "Contacto",
  footer_follow_us: "Síguenos",
  footer_site: "Mapa de sitio",
  footer_privacy: "Politicas de privacidad",
  footer_cookies: "Cookies",
  footer_faq: "Preguntas frecuentes",
};
