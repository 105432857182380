"use client";
import Button from "@/components/common/button";
import PrivacyPolicyModal from "@/components/privacy-policy-modal";
import useModal from "@/hooks/useModal";
import Image from "next/image";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import TruthSocial from "@/../public/images/abstract/social/truth-social-dark.svg";
import FaceSocial from "@/../public/images/abstract/social/facebook-social.svg";
import InstaSocial from "@/../public/images/abstract/social/instagram-social.svg";
import LinkedinSocial from "@/../public/images/abstract/social/linkedin-social.svg";
import YoutubeSocial from "@/../public/images/abstract/social/youtube-social.svg";
import dynamic from "next/dynamic";

import { footerBarEN } from "@/assets/dictionaries/en/footer-content";
import { footerBarES } from "@/assets/dictionaries/es/footer-content";

const SocialMediaLink = () => (
  <a
    href="#"
    className="text-white hover:text-gbci-accent transition-all duration-150"
  />
);

const Footer: FC = () => {
  const privacyModal = useModal();
  const [componentLoaded, setComponentLoaded] = useState(false);

  const DynamicCookies = dynamic(
    () => import("../../components/common/cookies"),
    {
      loading: () => <div />,
      ssr: false,
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setComponentLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const [footerContent, setFooterContent] = useState(footerBarEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setFooterContent(footerBarES);
        break;
      case "EN":
      default:
        setFooterContent(footerBarEN);
        break;
    }
  }, []);

  return (
    <>
      {componentLoaded ? <DynamicCookies /> : <></>}
      {privacyModal.isOpen && (
        <PrivacyPolicyModal handleClose={privacyModal.close} />
      )}
      <footer className="mt-16 py-16 bg-gradient-to-br from-slate-900 to-slate-600 text-white">
        <div className="px-page-xl flex flex-col gap-8">
          <div className="flex flex-col md:flex-row gap-16 justify-between">
            {/* <div className="flex flex-1 flex-col gap-4">
              <p className="font-bold text-2xl">Subscribe to our newsletter</p>

              <p>Get emails about our new products, offers and more!</p>

              <form className="flex flex-wrap gap-4">
                <input
                  type="text"
                  placeholder="Email"
                  className="text-black flex-1 p-4 rounded-md border border-slate-300"
                />

                <Button size="large">Subscribe</Button>
              </form>
            </div> */}
            <div className="flex flex-col gap-4">
              <p className="font-bold text-2xl">
                {footerContent.footer_contact}
              </p>

              <div className="text-xl">
                <p>+52 833 160 8894</p>
                <a
                  className="hover:text-gbci-accent"
                  href="mailto:contact@gbcigroup.com"
                >
                  contact@gbcigroup.com
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <p className="font-bold text-2xl">
                {footerContent.footer_follow_us}
              </p>
              <div className="flex gap-4">
                <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href="https://www.facebook.com/GBCIgroup?mibextid=ZbWKwL"
                  aria-label="Follow us on Facebook"
                >
                  <Image className="max-w-[80%]" src={FaceSocial} alt="" />
                </Link>
                <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href="https://www.instagram.com/gbcigroup/"
                  aria-label="Follow us on Instagram"
                >
                  <Image className="max-w-[80%]" src={InstaSocial} alt="" />
                </Link>
                <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href="https://www.linkedin.com/company/gbci"
                  aria-label="Follow us on LinkedIn"
                >
                  <Image className="max-w-[80%]" src={LinkedinSocial} alt="" />
                </Link>
              </div>
              <div className="flex gap-4">
                <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href="https://www.youtube.com/@gbcigroup"
                  aria-label="Follow us on Youtube"
                >
                  <Image className="max-w-[80%]" src={YoutubeSocial} alt="" />
                </Link>
                <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href="https://truthsocial.com/@gbcigroup"
                  aria-label="Follow us on Truth Social"
                >
                  <Image className="max-w-[80%]" src={TruthSocial} alt="" />
                </Link>
                {/* <Link
                  className="p-2 rounded-full bg-white w-10 h-10 flex justify-center items-center"
                  target="_blank"
                  href=""
                  aria-label="Follow us on Twitter"
                >
                  <i className="text-slate-900 text-xl fa-brands fa-x-twitter"></i>
                </Link> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row mt-8 gap-8 text-xl">
            <p>© 2024 GBCI Group</p>
            <nav>
              <ul className="flex flex-col gap-8 text-xl">
                <div className="flex flex-wrap gap-8 w-full">
                  {/* <li>
                    <Link href="/sitemap">{footerContent.footer_site}</Link>
                  </li> */}
                  <li>
                    <button onClick={privacyModal.open}>
                      {footerContent.footer_privacy}
                    </button>
                  </li>
                  <li>
                    <Link href="/cookies">{footerContent.footer_cookies}</Link>
                  </li>
                  {/* <li>
                    <Link href="/faq">{footerContent.footer_faq}</Link>
                  </li> */}
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
